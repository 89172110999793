import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManagePageLinkQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  programParticipantId: Types.Scalars['ID'];
}>;


export type ManagePageLinkQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', participants?: { __typename: 'PagedParticipants', total: number, results: Array<{ __typename: 'ProgramParticipantType', editShoppableAmbassadorLink?: string | null }> } | null } | null };


export const ManagePageLinkDocument = gql`
    query ManagePageLink($campaignId: ID!, $programParticipantId: ID!) {
  campaign(id: $campaignId) {
    participants(limit: 1, where: {participantIds: {any: [$programParticipantId]}}) {
      results {
        editShoppableAmbassadorLink
      }
      total
    }
  }
}
    `;

/**
 * __useManagePageLinkQuery__
 *
 * To run a query within a React component, call `useManagePageLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagePageLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagePageLinkQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      programParticipantId: // value for 'programParticipantId'
 *   },
 * });
 */
export function useManagePageLinkQuery(baseOptions: Apollo.QueryHookOptions<ManagePageLinkQuery, ManagePageLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagePageLinkQuery, ManagePageLinkQueryVariables>(ManagePageLinkDocument, options);
      }
export function useManagePageLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagePageLinkQuery, ManagePageLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagePageLinkQuery, ManagePageLinkQueryVariables>(ManagePageLinkDocument, options);
        }
export type ManagePageLinkQueryHookResult = ReturnType<typeof useManagePageLinkQuery>;
export type ManagePageLinkLazyQueryHookResult = ReturnType<typeof useManagePageLinkLazyQuery>;
export type ManagePageLinkQueryResult = Apollo.QueryResult<ManagePageLinkQuery, ManagePageLinkQueryVariables>;