import React, { useState, useContext, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Box, Typography } from '@material-ui/core'
import AddEditIntegration from './AddEditIntegration'
import { useCreateFulfillmentAccountMutation } from './operations/create-fulfillment-account.generated'
import { useUpdateFulfillmentAccountMutation } from './operations/update-fulfillment-account.generated'
import { useDeleteFulfillmentAccountMutation } from './operations/delete-fulfillment-account.generated'
import { useTestFulfillmentAccountMutation } from './operations/test-fulfillment-account.generated'
import { SocialTableHeader, SocialAccountRow, RefreshStatus } from './SocialAccountTable'
import TestIntegration from './TestIntegration'
import { OutboundTableHeader, OutboundAccountRow } from './OutboundAccountTable'
import Page from '../Page'
import { useUserIntegrationInfoQuery, UserIntegrationInfoDocument } from './operations/user-integration-info.generated'
import { FulfillmentAccountRowFragment } from './operations/fulfillment-account-row.generated'
import { SocialAccountRowFragment, SocialAccountRowFragmentDoc } from './operations/social-account-row.generated'
import withAuthorization from '../withAuthorization'
import { INTEGRATIONS_ROUTE, SHOPIFY_CALLBACK_ROUTE, TREMENDOUS_CALLBACK_ROUTE } from './routes'
import { Switch, Route } from 'react-router-dom'
import facebookSDK from '../facebook'
import TikApiSDK from '../tikapi'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { ReactComponent as TikTokIcon } from '../icons/tiktok_logo_filled.svg'
import useTitle from '../utils/use-title'
import { SocialAccountPickerDocument } from '../components/NavSidebar/operations/account-picker.generated'
import CustomerIntegrations from './CustomerIntegrations'
import EcommerceIntegrations, { ShopIntegrationTypeNames } from './EcommerceIntegrations'
import { CustomSourceIntegrationTypeNames } from './EcommIntegrationModal'
import ShopifyCallback from './ShopifyCallback'
import TremendousCallback from './TremendousCallback'
import { UpsellModalUpdaterContext } from '../components/UpsellModal'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import { createTypeNamePredicate } from '../types/utility'
import { captureException as SentryCaptureException } from '@sentry/browser'
import { useAddTikTokSocialAccountMutation } from './operations/add-tiktok-social-account.generated'
import { useUpdateTikTokSocialAccountMutation } from './operations/update-tiktok-social-account.generated'
import { useToast } from '../components/Alert/ToastProvider'
import { useAuthFbBusinessMutation } from './operations/auth-fb-business.generated'
import { SocialAccount } from '../gql-global'
import { FACEBOOK_APP_ID, FACEBOOK_CONFIG_ID } from '../facebook/constants'
import { useApolloClient } from '@apollo/client'

const customersIntegrationTypeNames = ['RefersionIntegration', 'ImpactIntegration', 'ImpactSubaffiliateIntegration'] as const
const isCustomerIntegration = createTypeNamePredicate(...customersIntegrationTypeNames)
const isShopifyIntegration = createTypeNamePredicate('ShopifyIntegration')
const isCustomSourceIntegration = createTypeNamePredicate(...CustomSourceIntegrationTypeNames)
const isEcommIntegration = createTypeNamePredicate(...ShopIntegrationTypeNames)
const isIGSocialAccount = createTypeNamePredicate('IGSocialAccount')

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountFormControl: {
      marginRight: theme.spacing(3),
      minWidth: 200,
      borderRadius: '2rem',
    },
    accountSelect: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    accountInput: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      color: 'white',
      borderRadius: '2rem',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    title: {
      flex: 1,
      lineHeight: 2,
    },
    sectionHeader: {
      marginBottom: theme.spacing(4),
    },
    largeButton: {
      marginTop: theme.spacing(3),
      borderRadius: 20,
    },
    icon: {
      fill: 'white',
    },
  }),
)

const Integrations: React.FC = () => {
  useTitle('Integrations')
  useIntercomOnPage('Integrations')
  const classes = useStyles()
  const [selectedIntegration, setSelectedIntegration] = useState<FulfillmentAccountRowFragment | null>(null)
  const [editOpen, setEditOpen] = useState(false)
  const [testIntegration, setTestIntegration] = useState<FulfillmentAccountRowFragment | null>(null)
  const [testOpen, setTestOpen] = useState(false)
  const [refreshStatuses, setRefreshStatuses] = useState<Record<string, RefreshStatus>>({})
  const { showToast } = useToast()
  const setModalOptions = useContext(UpsellModalUpdaterContext)
  const client = useApolloClient()

  useEffect(() => {
    ;(async function () {
      try {
        await TikApiSDK.init()
      } catch (e) {
        SentryCaptureException(e)
      }
      try {
        await facebookSDK.init(FACEBOOK_APP_ID)
      } catch (e) {
        SentryCaptureException(e)
      }
    })()
  }, [])

  const { data, loading, error } = useUserIntegrationInfoQuery()
  const [testIntegrationMutation, { data: testResults, error: testError, loading: testing }] =
    useTestFulfillmentAccountMutation()
  const [addTikTokSocialAccount] = useAddTikTokSocialAccountMutation({
    refetchQueries: [{ query: SocialAccountPickerDocument }],
    update(cache, { data: addTikTokSocialAccountData }) {
      if (!addTikTokSocialAccountData?.addTikTokSocialAccount?.ttSocialAccount || !data?.whoami?.account) return
      return cache.modify({
        id: cache.identify(data.whoami.account),
        fields: {
          socialAccounts(existingSocialAccounts) {
            const newTTSocialAccount = addTikTokSocialAccountData?.addTikTokSocialAccount?.ttSocialAccount
            const newSocialAccount = cache.writeFragment({
              data: newTTSocialAccount,
              fragment: SocialAccountRowFragmentDoc,
            })
            return [newSocialAccount, ...existingSocialAccounts]
          },
        },
      })
    },
  })
  const [updateTikTokSocialAccount] = useUpdateTikTokSocialAccountMutation()
  const [createFulfillmentAccount] = useCreateFulfillmentAccountMutation({
    update(cache) {
      const account = data?.whoami?.account
      if (!account) return

      const cacheId = cache.identify(account)
      cache.evict({ id: cacheId })
      cache.gc()
    },
    refetchQueries: [{ query: UserIntegrationInfoDocument }],
  })

  const [updateFulfillmentAccount] = useUpdateFulfillmentAccountMutation()
  const [deleteFulfillmentAccount] = useDeleteFulfillmentAccountMutation({
    refetchQueries: [{ query: UserIntegrationInfoDocument }],
  })
  const [authFbBusiness] = useAuthFbBusinessMutation({
    refetchQueries: [{ query: SocialAccountPickerDocument }],
    update(cache, { data: authData }) {
      if (!authData?.authFbBusiness?.fbUser?.socialAccounts || !data?.whoami?.account) return
      const updatedSA = authData.authFbBusiness.fbUser.socialAccounts
      cache.modify({
        id: cache.identify(data.whoami.account),
        fields: {
          socialAccounts(existing: SocialAccount[], { readField }) {
            const newAccounts = updatedSA.filter(a => !existing.some(e => readField('id', e) === a.id))
            return [...existing, ...newAccounts]
          },
        },
      })
    },
  })

  if (loading) return <p>Loading</p>
  if (error || (!loading && !data)) return <p>Error: {error?.message}</p>

  const setRefreshStatusesForAll = (status: RefreshStatus): void => {
    const userAccounts = data?.whoami?.account?.socialAccounts || []
    setRefreshStatuses(
      userAccounts.reduce(
        (a: Record<string, RefreshStatus>, v: SocialAccountRowFragment) =>
          v.platformId ? { ...a, [v.platformId]: status } : a,
        {},
      ),
    )
  }

  const updateSocial = async (isRefresh: boolean): Promise<unknown> => {
    const authType: facebook.LoginOptions['auth_type'] = isRefresh ? 'reauthorize' : undefined
    const authResponse = await facebookSDK.authForBusiness(FACEBOOK_CONFIG_ID, authType)
    if (authResponse.status !== 'connected') {
      setRefreshStatusesForAll({ type: 'error', message: 'Could not get facebook accounts for user' })
      return
    }

    if (!authResponse.authResponse.code && !authResponse.authResponse.accessToken) {
      setRefreshStatusesForAll({ type: 'error', message: 'Could not get facebook accounts for user' })
      return
    }

    if (data?.whoami?.isFbTester && data.whoami.account) {
      const igAccounts = await facebookSDK.getInstagramAccounts()
      client.cache.modify({
        id: client.cache.identify(data.whoami.account),
        fields: {
          socialAccounts(existing: SocialAccountRowFragment[]) {
            const newAccounts: SocialAccountRowFragment[] = igAccounts.map(i => ({
              __typename: 'IGSocialAccount',
              id: i.platformId,
              expiresAt: null,
              lastPull: new Date(),
              platformId: i.platformId,
              socialUser: {
                __typename: 'IGUser',
                id: i.username,
                username: i.username,
              },
            }))
            return [...existing, ...newAccounts]
          },
        },
      })
      return
    }

    try {
      const results = await authFbBusiness({
        variables: {
          code: authResponse.authResponse.code,
          token: authResponse.authResponse.accessToken,
        },
      })
      setRefreshStatuses(
        results.data?.authFbBusiness?.fbUser?.socialAccounts?.reduce((a, v) => {
          return { ...a, [v.platformId!]: { type: 'success' } }
        }, {}) || {},
      )
    } catch {
      setRefreshStatusesForAll({ type: 'error', message: 'Could not get facebook accounts for user' })
    }
  }

  const handleRefresh = (): void => {
    setRefreshStatusesForAll({ type: 'loading' })
    try {
      updateSocial(true)
    } catch {
      setRefreshStatusesForAll({ type: 'error', message: 'Could not get facebook accounts for user' })
    }
  }

  const handleAddSocial = (): void => {
    if (data?.whoami?.account?.organization.socialAccountLimit.hasHitLimit) {
      setModalOptions({
        isOpen: true,
        modalProps: {
          context: { reason: 'LIMIT', limit: 'SOCIAL_ACCOUNTS' },
          onCancel: () => setModalOptions({ isOpen: false }),
        },
      })
    } else {
      void updateSocial(false)
    }
  }

  async function handleAddTTSocial(): Promise<void> {
    try {
      const ttData = await TikApiSDK.authAndLogin()
      if (ttData && ttData.type !== 'error' && ttData.userInfo) {
        await addTikTokSocialAccount({
          variables: {
            accessToken: ttData.access_token,
            platformId: ttData.userInfo.id,
            ttSecUid: ttData.userInfo.sec_user_id,
            username: ttData.userInfo.username,
            followerCount: ttData.userInfo.followers_count || 0,
            imageUrl: ttData.userInfo.avatar,
          },
        })
        showToast({
          title: 'Success: TikTok User Added',
          message: `Added TikTok User ${ttData?.userInfo?.username}`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      }
    } catch (e) {
      showToast({
        title: 'Error Adding TikTok User',
        message: 'Something went wrong when adding this tiktok social account, please try again.',
      })
    }
  }

  async function handleManageTTSocial(): Promise<void> {
    try {
      const ttData = await TikApiSDK.authAndLogin()
      if (ttData && ttData.type !== 'error' && ttData.userInfo) {
        await updateTikTokSocialAccount({
          variables: {
            accessToken: ttData.access_token,
            ttSecUid: ttData.userInfo.sec_user_id,
            username: ttData.userInfo.username,
            followerCount: ttData.userInfo.followers_count || 0,
            imageUrl: ttData.userInfo.avatar,
          },
        })
        showToast({
          title: 'Success: TikTok User Updated',
          message: `Updated TikTok User ${ttData?.userInfo?.username}`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      } else {
        showToast({
          title: 'Error Updating TikTok User',
          message:
            ttData?.message || 'Something went wrong when updating this tiktok social account, please try again.',
        })
      }
    } catch (e) {
      showToast({
        title: 'Error Updating TikTok User',
        message: 'Something went wrong when updating this TikTok social account, please try again.',
      })
    }
  }

  const handleAddOutbound = (): void => {
    setSelectedIntegration(null)
    setEditOpen(true)
  }

  const handleEditOutbound = (integration: FulfillmentAccountRowFragment): void => {
    setSelectedIntegration(integration)
    setEditOpen(true)
  }

  const onTestIntegration = (integration: FulfillmentAccountRowFragment): void => {
    setTestIntegration(integration)
    setTestOpen(true)
  }

  const handleClose = (): void => {
    setEditOpen(false)
  }

  const handleSave = async (
    integrationId: number | null,
    accountType: string,
    key: string,
    fromEmail: string,
    fromName: string,
    guid: string,
    apiUrl: string,
    apiSecret: string,
    appId: string,
    smsConsentList: string | null,
    namespace: string | null,
  ) => {
    try {
      if (!integrationId) {
        await createFulfillmentAccount({
          variables: {
            accountType,
            key,
            fromEmail,
            fromName,
            guid,
            apiUrl,
            apiSecret,
            appId,
            smsConsentList,
            namespace,
          },
        })

        showToast({
          title: 'Success: integration created',
          message: `Integration Created`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      } else {
        await updateFulfillmentAccount({
          variables: {
            fulfillmentAccountId: `${integrationId}`,
            key,
            accountType,
            fromEmail,
            fromName,
            guid,
            apiUrl,
            apiSecret,
            appId,
            smsConsentList,
          },
        })
      }

      showToast({
        title: 'Success: integration updated',
        message: `Integration Updated`,
        severity: 'success',
        autoHideDuration: 5000,
      })
    } catch (err) {
      let msg
      if (err instanceof Error) {
        msg = err.message
      } else {
        msg = 'Please try again or ask support for help.'
      }
      showToast({ title: 'Error adding Integration', message: msg })
    }

    setEditOpen(false)
  }

  const handleDelete = async (integrationId: number) => {
    try {
      await deleteFulfillmentAccount({
        variables: {
          fulfillmentAccountId: `${integrationId}`,
        },
      })

      showToast({
        title: 'Success: integration deleted',
        message: `Integration Deleted`,
        severity: 'success',
        autoHideDuration: 5000,
      })
    } catch (err) {
      let msg
      if (err instanceof Error) {
        msg = err.message
      } else {
        msg = 'Please try again or ask support for help.'
      }
      showToast({ title: 'Error deleting Integration', message: msg })
    }

    setEditOpen(false)
  }

  const hasOutbound = !!data?.whoami?.account?.fulfillmentAccounts?.length
  const socialAccounts = data?.whoami?.account?.socialAccounts || []
  const integrations = data?.whoami?.account?.integrations || []
  const segments = data?.whoami?.account?.segments?.results || []
  const customerIntegrations = integrations.filter(isCustomerIntegration)
  const storefrontIntegrations = integrations.filter(isEcommIntegration)
  const customSourceIntegrations = integrations.filter(isCustomSourceIntegration)
  const shopifyIntegrations = integrations.filter(isShopifyIntegration)
  const hasTikTok = data?.whoami?.account?.organization?.hasTiktok
  const isLoudcrowdInternal = data?.whoami?.email?.endsWith('@loudcrowd.com')

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" flex={1}>
          <Typography variant="h5" className={classes.title}>
            Integrations
          </Typography>
        </Box>
        <Box mt={6}>
          <Box mb={8}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" mr={2}>
                <Typography variant="h6" className={classes.sectionHeader}>
                  Social platform
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                {hasTikTok && (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<TikTokIcon width={20} />}
                    onClick={handleAddTTSocial}
                  >
                    Add TikTok Account
                  </Button>
                )}
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<InstagramIcon width={20} />}
                  onClick={handleAddSocial}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  Add Instagram Account
                </Button>
              </Box>
            </Box>
            {!!socialAccounts.length && <SocialTableHeader />}
            {socialAccounts.map(socialAccount => (
              <SocialAccountRow
                socialAccount={socialAccount}
                handleRefresh={handleRefresh}
                key={socialAccount.id}
                refreshStatus={refreshStatuses[socialAccount.platformId || '']}
                canRefresh={isIGSocialAccount(socialAccount) && !data?.whoami?.isFbTester}
                manageTTSocialAccount={handleManageTTSocial}
              />
            ))}
          </Box>
          <Box mb={8}>
            <Typography variant="h6" className={classes.sectionHeader}>
              Outbound
            </Typography>
            {hasOutbound && <OutboundTableHeader />}
            {data?.whoami?.account?.fulfillmentAccounts?.map(fulfillmentAccount => (
              <OutboundAccountRow
                fulfillmentAccount={fulfillmentAccount}
                handleEdit={handleEditOutbound}
                onTest={onTestIntegration}
                key={fulfillmentAccount.id}
                organizationName={fulfillmentAccount?.organizationName || undefined}
                disabled={!!(fulfillmentAccount?.accountType === 'TREMENDOUS' && (!isLoudcrowdInternal && fulfillmentAccount?.isSubaffiliate))}
              />
            ))}
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleAddOutbound}
              className={classes.largeButton}
            >
              Add Integration
            </Button>
          </Box>
          <Box mb={8}>
            <Typography variant="h6" className={classes.sectionHeader}>
              Affiliate Programs
            </Typography>
            <CustomerIntegrations
              loading={loading}
              integrations={customerIntegrations}
              accountId={data?.whoami?.account?.id?.toString()}
            />
          </Box>
          <Box mb={8}>
            <Typography variant="h6" className={classes.sectionHeader}>
              Ecommerce
            </Typography>
            <EcommerceIntegrations
              loading={loading}
              segments={segments}
              customSourceIntegrations={customSourceIntegrations}
              shopIntegrations={storefrontIntegrations}
              accountId={data?.whoami?.account?.id?.toString()}
            />
          </Box>
        </Box>
        <AddEditIntegration
          open={editOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          integration={selectedIntegration}
        />
        {testIntegration && (
          <TestIntegration
            open={testOpen}
            onClose={() => setTestOpen(false)}
            integration={testIntegration}
            onTest={i => testIntegrationMutation({ variables: { data: i } })}
            testing={testing}
            result={
              testError?.message ||
              (testResults?.testFulfillment?.result && JSON.stringify(testResults?.testFulfillment?.result))
            }
          />
        )}
      </Box>
      <Switch>
        <Route path={SHOPIFY_CALLBACK_ROUTE.path} exact>
          <ShopifyCallback accountId={data?.whoami?.account?.id?.toString()} integrations={shopifyIntegrations} />
        </Route>
        <Route path={TREMENDOUS_CALLBACK_ROUTE.path} exact component={TremendousCallback} />
      </Switch>
    </Page>
  )
}

export default withAuthorization(INTEGRATIONS_ROUTE)(Integrations)
