import React, { useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Theme,
  Typography,
  useTheme,
  Checkbox,
  Select as MUISelect,
  Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { format } from 'date-fns'
import StoriesAvatarV2 from '../components/CustomerAvatar/StoriesAvatarV2'
import CustomerAuthStatusChip from './CustomerAuthStatusChip'
import {
  CustomerFieldEnumType,
  CustomFieldInput,
  FbUser,
  CustomerFieldInput,
  CustomFieldEnumType,
  ProgramTypeEnum,
  CustomerExternalIdInput,
  CommissionTierInput,
} from '../gql-global'
import { ReactComponent as ExternalIcon } from '../icons/external.svg'
import { ReactComponent as PhoneIcon } from '../icons/phone.svg'
import { ReactComponent as MailIcon } from '../icons/mail.svg'
import { ReactComponent as InsertLinkIcon } from '../icons/insert_link.svg'
import { ReactComponent as TiktokIcon } from '../icons/tiktok_logo_outline.svg'
import { ReactComponent as AffiliateCodeIcon } from '../icons/shopping-car.svg'
import { ReactComponent as PencilIcon } from '../icons/edit_pencil.svg'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { numberFormat } from '../utils/number-format'
import ExpandoText from '../components/ExpandoText'
import { Field, Form, Formik } from 'formik'
import LabelChip from '../components/labelChip'
import CustomField from '../components/CustomField/CustomField'
import { Select, TextField } from 'formik-material-ui'
import { STATES_LIST } from '../components/CustomField/states'
import { COUNTRIES_LIST } from '../components/CustomField/countries'
import { CustomerDetailsFragment } from './operations/customer-details.generated'
import { useToast } from '../components/Alert/ToastProvider'
import { CustomerDetailUserDataQuery } from './operations/customer-detail-user-data.generated'
import { CustomerQuery, CustomerQueryVariables } from './operations/customer.generated'
import CustomerDetailPrograms from './CustomerDetailPrograms'
import { CustomerRefetchType } from './CustomerDetail'
import CustomerDetailStats from './CustomerDetailStats'
import UpsertCommissionTierModal from '../campaign/campaign-detail/campaign-settings/UpsertCommissionTierModal'
import { useUpsertCommissionTierMutation } from '../campaign/campaign-detail/campaign-settings/operations/upsert-commission-tier.generated'
import { useSetParticipantCommissionTierMutation } from '../campaign/campaign-detail/operations/set-participant-commission-tier.generated'
import { CustomerCampaignsQuery } from './operations/customer-campaigns.generated'
import { useManagePageLinkLazyQuery } from './operations/manage-page-link.generated'
import { gray } from '../loudcrowd-theme'

type ValuesType = {
  address: string
  address2: string
  city: string
  state: string
  country: string
  zipCode: string
  firstName: string
  lastName: string
  customFields: Record<string, string | Date>
  externalIdentifiers: Record<string, string>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    igStat: {
      minWidth: 18,
      marginRight: 4,
    },
    bioText: {
      wordWrap: 'break-word',
    },
    usernameLinkIcon: {
      display: 'none',
    },
    usernameLink: {
      display: 'flex',
      alignItems: 'center',

      '&:hover $usernameLinkIcon': {
        display: 'inline',
      },
    },
    scrollContainer: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    withBorder: {
      border: `1px solid ${gray}`,
      borderRadius: '5px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    storefrontLinkButton: {
      fontWeight: 'bold',
      height: theme.spacing(8),
      minWidth: theme.spacing(30),
      marginRight: theme.spacing(2),
    },
    editCell: {
      '&:hover $editButton': {
        visibility: 'visible',
      },
      alignItems: 'center',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    editSpanCell: {
      '&:hover $spanEditButton': {
        visibility: 'visible',
      },
    },
    editButton: {
      visibility: 'hidden',
    },
    spanEditButton: {
      visibility: 'hidden',
    },
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 350,
    },
    formGroup: {
      marginBottom: theme.spacing(4),
    },
    formField: {
      width: '100%',
    },
    codeActive: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    codeExpired: {
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
  }),
)

export type CustomerDetailInfoCustomFieldType = NonNullable<
  NonNullable<CustomerDetailUserDataQuery['whoami']>['account']
>['customFields']

export type CustomerDetailInfoIntegrations = NonNullable<
  NonNullable<CustomerDetailUserDataQuery['whoami']>['account']
>['integrations']

export type AffiliateDiscountCodeType = NonNullable<
  NonNullable<NonNullable<CustomerQuery['socialAccount']>['customer']>['programParticipants']
>[0]['affiliateDiscountCodes']

export type ProgramAffiliateDiscountCode = {
  program: { id: string; name: string }
  ecommDiscountCodeId: string
}

type StorefrontData = {
  programParticipantId: number
  campaignId: number
  shoppableAmbassadorLink: string
  // We get a fresh link on click on the Manage Page button, but this initial value allows
  // us to know if the backend is going to give us a link beforehand to display the button or not
  // (as there are some situations where the link is not available)
  initialEditShoppableAmbassadorLink?: string | null
}

interface CustomerDetailInfoProps {
  socialAccountId: string
  accountCustomFields?: CustomerDetailInfoCustomFieldType | null
  customer?: CustomerDetailsFragment | null
  customerAddress?: string | null
  socialAccountType?: string | null
  storefronts: StorefrontData[]
  discountCodes?: AffiliateDiscountCodeType | undefined | null
  loading?: boolean
  hasActiveStory?: boolean
  fbUsers: FbUser[]
  programAffiliateDiscountCodes?: ProgramAffiliateDiscountCode[]
  customerQueryVariables: CustomerQueryVariables
  customerIdIntegrations?: CustomerDetailInfoIntegrations | null
  setSegmentEditor: () => void
  editEmail: () => void
  editPhone: () => void
  editTiktok: () => void
  editAffiliateCodes: (
    programsWithMissingCodes: ProgramAffiliateDiscountCode[] | null,
    selectedProgram: ProgramAffiliateDiscountCode | null,
    selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null,
  ) => void
  editCustomFieldsToggle: () => void
  editingCustomFields: boolean
  onDetailsFormSubmit: (
    customFields: CustomFieldInput[],
    customerFields: CustomerFieldInput[],
    externalIdentifiers: CustomerExternalIdInput[],
  ) => void
  onRemoveSegment: (id: number) => void
  customerRefetch: CustomerRefetchType
  campaignsData?: CustomerCampaignsQuery | null
}

const CustomerDetailInfo: React.FC<CustomerDetailInfoProps> = ({
  socialAccountId,
  accountCustomFields,
  loading = false,
  hasActiveStory,
  customer,
  fbUsers,
  socialAccountType,
  storefronts,
  setSegmentEditor,
  editEmail,
  editPhone,
  editTiktok,
  editCustomFieldsToggle,
  editingCustomFields,
  onDetailsFormSubmit,
  onRemoveSegment,
  discountCodes,
  programAffiliateDiscountCodes,
  editAffiliateCodes,
  customerQueryVariables,
  customerRefetch,
  customerIdIntegrations,
  campaignsData,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { showToast } = useToast()

  const [commissionTierModalOpen, setCommissionTierModalOpen] = useState(false)
  const [selectedProgramParticipant, setSelectedProgramParticipant] = useState<
    CustomerDetailsFragment['programParticipants'][number] | null
  >(null)

  const [upsertCommissionTier] = useUpsertCommissionTierMutation({
    onError: () => {
      showToast({
        title: 'Error: Saving Commission Tier',
        message: 'Something went wrong while saving the Commission Tier, please try again.',
        severity: 'error',
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: 'Commission Tier successfully created',
        severity: 'success',
      })
    },
  })

  const [setParticipantCommissionTierMutation] = useSetParticipantCommissionTierMutation({
    refetchQueries: ['CustomerActivities'],
  })

  const [executeManagePageLink] = useManagePageLinkLazyQuery({
    defaultOptions: { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true },
  })

  const igUser = customer?.igUser
  const ttUser = customer?.ttUser
  const isIGSocialAccount = socialAccountType === 'instagram'
  const isTTSocialAccount = socialAccountType === 'tiktok'

  let customerSocialUser
  let socialUserLink = null
  if (isIGSocialAccount && igUser) {
    customerSocialUser = igUser
    socialUserLink = 'https://www.instagram.com/'
  } else if (isTTSocialAccount && ttUser) {
    customerSocialUser = ttUser
    socialUserLink = 'https://www.tiktok.com/@'
  }

  const email = customer?.email
  const phone = customer?.phone
  const address = customer?.address
  const address2 = customer?.address2
  const city = customer?.city
  const state = customer?.state
  const country = customer?.country
  const zipCode = customer?.zipCode
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const username = customerSocialUser?.username
  const biography = customerSocialUser?.biography
  const followerCount = customerSocialUser?.followerCount
  const avatarUrl = customerSocialUser?.avatarUrl
  const socialUserUsernameLink = `${socialUserLink}${username}`
  const ttUsername = customer?.ttUser?.username
  const programParticipantsLength = customer?.programParticipants?.length
  const postCount = customer?.mentionStats?.postCount
  const emv = customer?.mentionStats?.emv
  const impressions = customer?.mentionStats?.impressions
  const avgEngagementRate = customer?.mentionStats?.avgEngagementRate
  const customerCustomFields = customer?.customFields
  let customerAddress = ''
  if (customer) {
    const cAddress = address ? `${address}, ` : ''
    const cAddress2 = address2 ? `${address2}, ` : ''
    const cCity = city ? `${city}, ` : ''
    const cState = state ? `${state}, ` : ''
    const cCountry = country ? `${country}, ` : ''
    const cZipCode = zipCode || ''
    customerAddress = `${cAddress} ${cAddress2} ${cCity} ${cState} ${cCountry} ${cZipCode}`
  }

  function onSubmit(fields: ValuesType) {
    const customFieldInputs: CustomFieldInput[] = []
    const customerFieldsInputs: CustomerFieldInput[] = []
    const { address, address2, city, state, country, zipCode, firstName, lastName, customFields, externalIdentifiers } =
      fields
    if (address) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.Address,
        value: address,
      })
    }
    if (address2) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.Address_2,
        value: address2,
      })
    }
    if (city) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.City,
        value: city,
      })
    }
    if (state) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.State,
        value: state,
      })
    }
    if (country) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.Country,
        value: country,
      })
    }
    if (zipCode) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.ZipCode,
        value: zipCode,
      })
    }
    if (firstName) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.FirstName,
        value: firstName,
      })
    }
    if (lastName) {
      customerFieldsInputs.push({
        type: CustomerFieldEnumType.LastName,
        value: lastName,
      })
    }

    Object.entries(customFields).forEach(e => {
      const fieldValue = e[1]
      const stringValue: string =
        fieldValue instanceof Date
          ? fieldValue.getFullYear() + '-' + (fieldValue.getMonth() + 1) + '-' + fieldValue.getDate()
          : fieldValue.trim()
      customFieldInputs.push({
        id: e[0],
        value: stringValue,
      })
    })
    const externalIdInputs = []
    for (const id in externalIdentifiers) {
      externalIdInputs.push({
        externalId: externalIdentifiers[id] ?? '',
        integrationId: id,
      })
    }
    onDetailsFormSubmit(customFieldInputs, customerFieldsInputs, externalIdInputs)
  }

  function setParticipantCommissionTier(commissionTierId: string, participantId: number, tierName: string): void {
    setParticipantCommissionTierMutation({
      variables: {
        commissionTierId: commissionTierId,
        participantIds: [participantId.toString()],
      },
    })
      .then(result => {
        customerRefetch()
        showToast({
          title: 'Success: Setting Commission Tier',
          message: `Commission Tier '${tierName}' was applied to ${username}`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Setting Commission Tier',
          message: 'Something went wrong when setting the commission tier to the member, please try again.',
        })
      })
  }

  const saveCommissionTier = async (newTier: CommissionTierInput) => {
    const result = await upsertCommissionTier({
      variables: {
        programId: selectedProgramParticipant?.program.id!,
        data: {
          name: newTier.name,
          ratePercentage: newTier.ratePercentage,
          rules: newTier.rules,
        },
      },
    })
    if (result.errors) return
    setParticipantCommissionTier(
      result.data?.upsertCommissionTier?.commissionTier?.id!,
      selectedProgramParticipant?.id!,
      newTier.name,
    )
    setCommissionTierModalOpen(false)
  }

  const handleCommissionTierSelect = (
    commissionTier: { id: string; name: string } | undefined,
    programParticipant: CustomerDetailsFragment['programParticipants'][number],
  ) => {
    const programParticipantId = programParticipant.id
    setSelectedProgramParticipant(programParticipant)
    if (!commissionTier) {
      setCommissionTierModalOpen(true)
      return
    }
    setParticipantCommissionTier(commissionTier.id, programParticipantId, commissionTier.name)
  }

  let availableDiscountCodes: AffiliateDiscountCodeType = []
  let programsWithMissingCodes: ProgramAffiliateDiscountCode[] = []

  for (let programAffiliateDiscountCode of programAffiliateDiscountCodes || []) {
    const activeProgramAffiliateDiscountCodes = discountCodes?.filter(
      discountCode => discountCode.ecommDiscountCode?.id === programAffiliateDiscountCode.ecommDiscountCodeId,
    )

    if (!!activeProgramAffiliateDiscountCodes?.length) {
      availableDiscountCodes = availableDiscountCodes.concat(activeProgramAffiliateDiscountCodes)
    } else {
      programsWithMissingCodes.push(programAffiliateDiscountCode)
    }
  }

  function convertCustomFieldsToFormikObject() {
    const customFieldObject = accountCustomFields?.reduce((a, v) => {
      const customerCustomField = customerCustomFields?.find(ccf => ccf.customField.id === v.id)
      a[v.id] =
        (customerCustomField?.customField?.type === 'DATE'
          ? customerCustomField.dateValue
          : customerCustomField?.value) || ''
      return a
    }, {} as Record<string, string | Date>)

    const externalIdentifiers = customerIdIntegrations?.reduce((a, v) => {
      const existingValue = customer?.externalIdentifiers.find(eix => eix.integration.id === v.id)
      a[v.id] = existingValue?.externalId ?? ''
      return a
    }, {} as Record<string, string>)

    const customerFieldsFormikType: ValuesType = {
      address: address || '',
      address2: address2 || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
      city: city || '',
      firstName: firstName || '',
      lastName: lastName || '',
      customFields: customFieldObject || {},
      externalIdentifiers: externalIdentifiers || {},
    }
    return customerFieldsFormikType
  }

  function onCopyAmbassadorLink(url: string) {
    navigator.clipboard.writeText(url)
    showToast({ message: 'Copied to clipboard!', severity: 'info' })
  }

  const onManagePageClick = async (ppId: number, campaignId: number) => {
    const result = await executeManagePageLink({
      variables: { campaignId: campaignId.toString(), programParticipantId: ppId.toString() },
    })
    if (!result.data?.campaign?.participants?.results?.[0]?.editShoppableAmbassadorLink) {
      showToast({
        title: `Error: Couldn't open Manage Page tab`,
        message: 'Something went wrong while generating the page management credentials.',
        severity: 'error',
        autoHideDuration: 5000,
      })
      return
    }
    window.open(
      result.data.campaign.participants.results[0].editShoppableAmbassadorLink,
      '_blank',
      'noopener noreferrer',
    )
  }

  const initialValues = accountCustomFields ? convertCustomFieldsToFormikObject() : null
  const hasCustomerAuth = !!(programParticipantsLength && programParticipantsLength > 0 && fbUsers.length > 0)
  // for now only custom integration ids can be edited
  const editableCustomerIdIntegrations = customerIdIntegrations?.filter(i => i.__typename === 'CustomStoreIntegration')

  return (
    <>
      <Paper style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row">
          <Box padding={6} display="flex" style={{ width: 651 }}>
            <div>
              <StoriesAvatarV2 loading={loading} hasActiveStory={hasActiveStory} avatarUrl={avatarUrl} size="xLarge" />
            </div>
            <Box marginLeft={5} width="100%" overflow="auto">
              <Box marginBottom={3}>
                <Box mb={1}>
                  <Typography className={classes.h7} color="primary">
                    {loading && <Skeleton component="span" />}
                    {!loading && username && socialUserUsernameLink && (
                      <Link
                        className={classes.usernameLink}
                        href={socialUserUsernameLink}
                        target="_blank"
                        rel="noopenner noreferrer"
                      >
                        {username}
                        <IconButton size="small" color="secondary" className={classes.usernameLinkIcon}>
                          <ExternalIcon width={12} height={12} />
                        </IconButton>
                      </Link>
                    )}
                  </Typography>
                </Box>
                <CustomerDetailPrograms
                  customer={customer}
                  username={username}
                  customerQueryVariables={customerQueryVariables}
                  customerRefetch={customerRefetch}
                />
                {hasCustomerAuth && (
                  <Box mb={4}>
                    <CustomerAuthStatusChip fbUsers={fbUsers} />
                  </Box>
                )}
                <Box display="flex" flexWrap="wrap">
                  <Box display="flex" mr={6}>
                    <Typography variant="body2" className={`${classes.bold} ${classes.igStat}`}>
                      {loading && <Skeleton component="span" />}
                      {!loading ? numberFormat(followerCount || 0, { format: 'number' }) : ''}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      follower
                      {followerCount && followerCount === 1 ? '' : 's'}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body2" className={`${classes.bold} ${classes.igStat}`}>
                      {loading && <Skeleton component="span" />}
                      {!loading ? numberFormat(postCount || 0, { format: 'number' }) : ''}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      post
                      {postCount && postCount === 1 ? '' : 's'}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" mt={2}>
                  {loading ? (
                    <Skeleton component="span" style={{ width: theme.spacing(10) }} />
                  ) : (
                    <>
                      <Box display="flex" flexWrap="wrap" style={{ alignItems: 'center' }} className={classes.editCell}>
                        <MailIcon />
                        <Typography color="textSecondary" variant="body2" style={{ marginLeft: theme.spacing(1.5) }}>
                          {email ? email : 'Add Email'}
                        </Typography>
                        <IconButton size="small" color="secondary" className={classes.editButton} onClick={editEmail}>
                          <PencilIcon width={12} height={12} />
                        </IconButton>
                      </Box>
                      <Box display="flex" flexWrap="wrap" className={classes.editCell}>
                        <PhoneIcon />
                        <Typography color="textSecondary" variant="body2" style={{ marginLeft: theme.spacing(1.5) }}>
                          {phone ? phone : 'Add Phone'}
                        </Typography>
                        <IconButton size="small" color="secondary" className={classes.editButton} onClick={editPhone}>
                          <PencilIcon width={12} height={12} />
                        </IconButton>
                      </Box>
                      {isIGSocialAccount && (
                        <Box display="flex" flexWrap="wrap" className={classes.editCell}>
                          <TiktokIcon />
                          <Typography color="textSecondary" variant="body2" style={{ marginLeft: theme.spacing(1.5) }}>
                            {ttUsername ? ttUsername : 'Add TikTok'}
                          </Typography>
                          <IconButton
                            size="small"
                            color="secondary"
                            className={classes.editButton}
                            onClick={editTiktok}
                          >
                            <PencilIcon width={12} height={12} />
                          </IconButton>
                        </Box>
                      )}
                      {isTTSocialAccount && igUser && igUser.username && (
                        <Box display="flex" flexWrap="wrap" className={classes.editCell}>
                          <InstagramIcon height={16} width={16} />
                          <Typography color="textSecondary" variant="body2" style={{ marginLeft: theme.spacing(1.5) }}>
                            {igUser.username}
                          </Typography>
                        </Box>
                      )}
                      {(!!availableDiscountCodes?.length || !!programsWithMissingCodes?.length) && (
                        <Box display="flex" flexWrap="wrap" className={classes.editCell}>
                          <AffiliateCodeIcon />
                          <Typography color="textSecondary" variant="body2" style={{ marginLeft: theme.spacing(1.5) }}>
                            Affiliate Codes:
                          </Typography>
                          <Box ml={1} display="inline-flex">
                            {availableDiscountCodes.map((item, index) => (
                              <Box ml={1} display="inline-flex" className={classes.editSpanCell} key={index}>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                  key={index}
                                  className={`${item.isExpired ? classes.codeExpired : classes.codeActive} ${
                                    classes.editSpanCell
                                  }`}
                                >
                                  {index > 0 && '/ '}
                                  {item.code}
                                  {item.isExpired ? ' (expired)' : ''}
                                </Typography>
                                {!item.isExpired && (
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    className={classes.spanEditButton}
                                    onClick={() =>
                                      editAffiliateCodes(
                                        null,
                                        programAffiliateDiscountCodes?.filter(
                                          program => program.ecommDiscountCodeId === item.ecommDiscountCode?.id,
                                        )[0] || null,
                                        { code: item.code, ecommDiscountRedeemCodeId: item.id },
                                      )
                                    }
                                  >
                                    <PencilIcon width={12} height={12} />
                                  </IconButton>
                                )}
                              </Box>
                            ))}
                            {!!programsWithMissingCodes?.length && (
                              <Box ml={1} display="inline-flex" className={classes.editSpanCell}>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                  style={{ marginLeft: theme.spacing(1.5) }}
                                >
                                  Add code
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    className={classes.spanEditButton}
                                    onClick={() => {
                                      editAffiliateCodes(programsWithMissingCodes, null, null)
                                    }}
                                  >
                                    <PencilIcon width={12} height={12} />
                                  </IconButton>
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                      {storefronts.map((storefront: StorefrontData, index: number, iterable: StorefrontData[]) => {
                        return (
                          <Box
                            style={{
                              width: '100%',
                              ...(iterable.length - 1 !== index ? { marginBottom: theme.spacing(2) } : {}),
                            }}
                            key={`customer_ambassador_url_${index}`}
                            className={`${classes.withBorder}`}
                          >
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              style={{
                                width: '100%',
                              }}
                              className={classes.editCell}
                            >
                              <InsertLinkIcon />
                              <Tooltip title={storefront.shoppableAmbassadorLink} placement="top">
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                  style={{
                                    marginLeft: theme.spacing(1.5),
                                    flex: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {storefront.shoppableAmbassadorLink}
                                </Typography>
                              </Tooltip>
                            </Box>
                            <Box
                              style={{
                                marginLeft: theme.spacing(4),
                              }}
                            >
                              {!!storefront.initialEditShoppableAmbassadorLink && (
                                <Button
                                  onClick={() =>
                                    onManagePageClick(storefront.programParticipantId, storefront.campaignId)
                                  }
                                  variant="contained"
                                  color="primary"
                                  className={classes.storefrontLinkButton}
                                >
                                  Manage Page
                                </Button>
                              )}
                              <Button
                                onClick={() => onCopyAmbassadorLink(storefront.shoppableAmbassadorLink)}
                                variant="outlined"
                                color="primary"
                                className={classes.storefrontLinkButton}
                              >
                                Copy Link
                              </Button>
                            </Box>
                          </Box>
                        )
                      })}
                    </>
                  )}
                </Box>
              </Box>
              <Box marginBottom={5}>
                <Typography variant="subtitle2">
                  {loading && <Skeleton component="span" />}
                  {!loading ? customerSocialUser?.fullName : ''}
                </Typography>
                <Typography component={'span'} variant="body2" className={classes.bioText}>
                  {loading && <Skeleton component="span" />}
                  <Box marginBottom={5} className={classes.scrollContainer}>
                    <ExpandoText minLines={1}>
                      {!loading ? biography : ''}
                      {/* TODO: if the bio has @usernames link to those customer pages if we have them */}
                    </ExpandoText>
                  </Box>
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" style={{ alignItems: 'center' }}>
                {customer?.programParticipants
                  ?.filter(pp => {
                    return (
                      pp.program.programType === ProgramTypeEnum.ShoppableAmbassador && pp.program.commissionsEnabled
                    )
                  })
                  .map(pp => (
                    <Box style={{ display: 'grid', gridTemplateColumns: '1fr 200px', width: '100%' }}>
                      <Typography variant="subtitle2">{pp.program.name} Commission Tier</Typography>
                      <MUISelect
                        onChange={selectItem => {
                          const ct = pp.program.commissionTiers?.find(ct => ct.id === selectItem.target.value)
                          handleCommissionTierSelect(ct, pp)
                        }}
                        value={pp.programCommissionTier?.id}
                      >
                        {pp.program.commissionTiers?.map(ct => (
                          <MenuItem value={ct.id} key={ct.id}>
                            {ct.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={undefined} key={undefined}>
                          Create new tier
                        </MenuItem>
                      </MUISelect>
                    </Box>
                  ))}
              </Box>
              <Box>
                <Box display="flex" flexWrap="wrap" style={{ alignItems: 'center' }}>
                  <Typography variant="subtitle2">Segments</Typography>
                  <Button color="primary" disabled={loading} onClick={setSegmentEditor}>
                    <Typography variant="subtitle2">+ Add to segment</Typography>
                  </Button>
                </Box>
                <Box mt={2} display="flex" flexWrap="wrap">
                  {customer?.segments?.map(s => (
                    <Box mt={1} key={s.id}>
                      <LabelChip labelId={s.id} label={s.name} onDeleteCallback={onRemoveSegment} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Divider orientation="vertical" style={{ height: '100%' }} />
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box mx={5}>
              <CustomerDetailStats
                socialAccountId={socialAccountId}
                customer={customer}
                isShoppableAmbassador={
                  (
                    customer?.programParticipants.filter(
                      pp => pp.program.programType === ProgramTypeEnum.ShoppableAmbassador,
                    ) || []
                  ).length > 0
                }
              />
              <Box display="flex" flexWrap="wrap" mt={6} px={3} width={'100%'}>
                <Box flex={1} ml={2} mr={5}>
                  <Typography color="secondary" variant="body2">
                    Impressions
                  </Typography>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                    {loading && <Skeleton component="span" />}
                    {!loading && impressions && numberFormat(impressions)}
                  </Typography>
                </Box>
                <Box flex={1} ml={2} mr={5}>
                  <Typography color="secondary" variant="body2">
                    Eng. rate
                  </Typography>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                    {loading && <Skeleton component="span" />}
                    {!loading && avgEngagementRate && numberFormat(avgEngagementRate, { format: 'percent' })}
                  </Typography>
                </Box>
                <Box flex={1} ml={2} mr={5}>
                  <Typography color="secondary" variant="body2">
                    EMV
                  </Typography>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                    {loading && <Skeleton component="span" />}
                    {!loading && emv && numberFormat(emv, { format: 'currency' })}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider style={{ width: '100%', marginTop: theme.spacing(10) }} />
            <Box>
              <Box display="flex" flexWrap="wrap" mt={5} ml={10} alignItems="center">
                <Typography variant="subtitle1">Details</Typography>
                <Button variant="text" color="primary" disabled={loading} onClick={editCustomFieldsToggle}>
                  <Typography variant="subtitle1">Edit</Typography>
                </Button>
              </Box>
              <Box ml={10} mb={5}>
                <ExpandoText minLines={accountCustomFields && accountCustomFields.length > 0 ? 8 : 5}>
                  <Box marginBottom={2}>
                    <Box marginY={5}>
                      <Typography variant="body2">Name</Typography>
                      <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                        {`${firstName} ${lastName}` || ''}
                      </Typography>
                    </Box>
                    <Box marginY={5}>
                      <Typography variant="body2">Address</Typography>
                      <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                        {customerAddress}
                      </Typography>
                    </Box>
                    {accountCustomFields &&
                      accountCustomFields.length > 0 &&
                      accountCustomFields.map((accountCustomField: CustomerDetailInfoCustomFieldType[0]) => {
                        const title = accountCustomField.title
                        const customerCustomField =
                          (customerCustomFields &&
                            customerCustomFields.find(ccf => ccf.customField.id === accountCustomField.id)) ||
                          null

                        if (accountCustomField.type !== CustomFieldEnumType.TermsConditions) {
                          const getCustomFieldValue = () => {
                            if (
                              customerCustomField?.customField?.type === CustomFieldEnumType.Date &&
                              customerCustomField?.dateValue
                            ) {
                              return format(customerCustomField?.dateValue, 'MM/dd/yyyy')
                            }

                            return customerCustomField?.value || ''
                          }

                          const customFieldValue = getCustomFieldValue()

                          return !['CHECKBOX', 'SMS_CONSENT'].includes(accountCustomField.type) ? (
                            <Box marginY={5} key={accountCustomField.id} marginRight={20}>
                              <Typography variant="body2">{title}</Typography>
                              <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                                {customFieldValue}
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              marginY={5}
                              key={accountCustomField.id}
                              marginRight={20}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              padding={0}
                            >
                              <Checkbox
                                checked={customFieldValue === 'true'}
                                disabled
                                style={{ color: theme.palette.grey[600], paddingLeft: 0 }}
                              />
                              {title}
                            </Box>
                          )
                        }
                        return null
                      })}
                    {customer?.externalIdentifiers?.map(ci => (
                      <Box marginY={5} key={ci.id}>
                        <Typography variant="body2">{ci.integration.name} Customer ID</Typography>
                        <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                          {ci.externalId}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </ExpandoText>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <UpsertCommissionTierModal
        campaignId={campaignsData?.socialAccount?.campaigns
          ?.find(c => c.program?.id === selectedProgramParticipant?.program.id)
          ?.id.toString()}
        open={commissionTierModalOpen}
        existingCommissionTiers={selectedProgramParticipant?.program.commissionTiers}
        cancelCallback={() => setCommissionTierModalOpen(false)}
        doneCallback={saveCommissionTier}
        selectedCommissionTier={null}
      />
      {initialValues && (
        <Dialog open={editingCustomFields} onClose={editCustomFieldsToggle}>
          <Formik<ValuesType> initialValues={initialValues} onSubmit={onSubmit}>
            <Form noValidate>
              <DialogTitle>Details</DialogTitle>
              <DialogContent style={{ paddingTop: 0, width: 300 }}>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    label="First Name"
                    type="text"
                    name="firstName"
                  />
                </Box>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    label="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Box>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    value={customerAddress}
                    component={TextField}
                    label="Street Address"
                    type="text"
                    name="address"
                  />
                </Box>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    value={address2}
                    component={TextField}
                    label="Street Address (line 2)"
                    type="text"
                    name="address2"
                  />
                </Box>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    value={city}
                    component={TextField}
                    label="City"
                    type="text"
                    name="city"
                  />
                </Box>
                <Box className={classes.formGroup}>
                  <InputLabel htmlFor={`${username}-state-select`}>State</InputLabel>
                  <Field
                    labelId={`${username}-state-select`}
                    className={classes.formField}
                    component={Select}
                    type="select"
                    name="state"
                  >
                    {STATES_LIST &&
                      STATES_LIST?.map(o => (
                        <MenuItem key={`option-${o}`} value={o}>
                          {o}
                        </MenuItem>
                      ))}
                    <MenuItem key="none" value="">
                      State
                    </MenuItem>
                  </Field>
                </Box>
                <Box className={classes.formGroup}>
                  <InputLabel htmlFor={`${username}-state-select`}>Country</InputLabel>
                  <Field
                    labelId={`${username}-country-select`}
                    className={classes.formField}
                    component={Select}
                    type="select"
                    name="country"
                  >
                    {COUNTRIES_LIST &&
                      COUNTRIES_LIST?.map(o => (
                        <MenuItem key={`option-${o}`} value={o}>
                          {o}
                        </MenuItem>
                      ))}
                    <MenuItem key="none" value="">
                      Country
                    </MenuItem>
                  </Field>
                </Box>
                <Box className={classes.formGroup}>
                  <Field
                    className={classes.formField}
                    value={customerAddress}
                    component={TextField}
                    label="Zip Code"
                    type="text"
                    name="zipCode"
                  />
                </Box>
                {accountCustomFields &&
                  accountCustomFields.length > 0 &&
                  accountCustomFields.map(accountCustomField => {
                    const customerCustomField =
                      (customerCustomFields &&
                        customerCustomFields.find(ccf => ccf.customField.id === accountCustomField.id)) ||
                      null
                    return (
                      <CustomField
                        key={accountCustomField.id}
                        customField={accountCustomField}
                        value={customerCustomField?.value || ''}
                      />
                    )
                  })}
                {editableCustomerIdIntegrations?.map((i, index) => {
                  const existingValue = customer?.externalIdentifiers.find(ci => ci.integration.id === i.id)
                  return (
                    <Box className={classes.formGroup} key={index}>
                      <Field
                        className={classes.formField}
                        value={existingValue || ''}
                        component={TextField}
                        label={`${i.name} Customer ID`}
                        type="text"
                        name={`externalIdentifiers.${i.id}`}
                      />
                    </Box>
                  )
                })}
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={editCustomFieldsToggle}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </Dialog>
      )}
    </>
  )
}

export default CustomerDetailInfo
