import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
export type RewardIntegration_BluecoreIntegration_Fragment = { __typename: 'BluecoreIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_BrazeIntegration_Fragment = { __typename: 'BrazeIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_CommerceCloudIntegration_Fragment = { __typename: 'CommerceCloudIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_CustomStoreIntegration_Fragment = { __typename: 'CustomStoreIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_EmarsysIntegration_Fragment = { __typename: 'EmarsysIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_GoogleIntegration_Fragment = { __typename: 'GoogleIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_GrowaveIntegration_Fragment = { __typename: 'GrowaveIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_ImpactIntegration_Fragment = { __typename: 'ImpactIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_ImpactSubaffiliateIntegration_Fragment = { __typename: 'ImpactSubaffiliateIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_KlaviyoIntegration_Fragment = { __typename: 'KlaviyoIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_LoyaltyLionIntegration_Fragment = { __typename: 'LoyaltyLionIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_MandrillIntegration_Fragment = { __typename: 'MandrillIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_OmetriaIntegration_Fragment = { __typename: 'OmetriaIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_RakutenSubaffiliateIntegration_Fragment = { __typename: 'RakutenSubaffiliateIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_RefersionIntegration_Fragment = { __typename: 'RefersionIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_SftpIntegration_Fragment = { __typename: 'SFTPIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_SftpPullIntegration_Fragment = { __typename: 'SFTPPullIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_SalesforceOcapiIntegration_Fragment = { __typename: 'SalesforceOcapiIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_SendinblueIntegration_Fragment = { __typename: 'SendinblueIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_ShopifyIntegration_Fragment = { __typename: 'ShopifyIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_SmileIntegration_Fragment = { __typename: 'SmileIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_TremendousIntegration_Fragment = { __typename: 'TremendousIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string, tremendousCampaigns: Array<{ __typename: 'TremendousCampaign', code: string, name: string }> };

export type RewardIntegration_WebhookIntegration_Fragment = { __typename: 'WebhookIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_YotpoIntegration_Fragment = { __typename: 'YotpoIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_YotpoVizIntegration_Fragment = { __typename: 'YotpoVizIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegration_ZaiusIntegration_Fragment = { __typename: 'ZaiusIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, deliveryMethods: Array<Types.IntegrationDeliveryMethodEnum>, integrationType: Types.IntegrationType, name: string };

export type RewardIntegrationFragment = RewardIntegration_BluecoreIntegration_Fragment | RewardIntegration_BrazeIntegration_Fragment | RewardIntegration_CommerceCloudIntegration_Fragment | RewardIntegration_CustomStoreIntegration_Fragment | RewardIntegration_EmarsysIntegration_Fragment | RewardIntegration_GoogleIntegration_Fragment | RewardIntegration_GrowaveIntegration_Fragment | RewardIntegration_ImpactIntegration_Fragment | RewardIntegration_ImpactSubaffiliateIntegration_Fragment | RewardIntegration_KlaviyoIntegration_Fragment | RewardIntegration_LoyaltyLionIntegration_Fragment | RewardIntegration_MandrillIntegration_Fragment | RewardIntegration_OmetriaIntegration_Fragment | RewardIntegration_RakutenSubaffiliateIntegration_Fragment | RewardIntegration_RefersionIntegration_Fragment | RewardIntegration_SftpIntegration_Fragment | RewardIntegration_SftpPullIntegration_Fragment | RewardIntegration_SalesforceOcapiIntegration_Fragment | RewardIntegration_SendinblueIntegration_Fragment | RewardIntegration_ShopifyIntegration_Fragment | RewardIntegration_SmileIntegration_Fragment | RewardIntegration_TremendousIntegration_Fragment | RewardIntegration_WebhookIntegration_Fragment | RewardIntegration_YotpoIntegration_Fragment | RewardIntegration_YotpoVizIntegration_Fragment | RewardIntegration_ZaiusIntegration_Fragment;

export const RewardIntegrationFragmentDoc = gql`
    fragment RewardIntegration on Integration {
  ... on Node {
    id
  }
  ... on Integration {
    capabilities
    deliveryMethods
    integrationType
    name
  }
  ... on TremendousIntegration {
    tremendousCampaigns {
      code
      name
    }
  }
}
    `;