import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommissionPayoutSettingsUserDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommissionPayoutSettingsUserDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', email: string, id: number } | null };


export const CommissionPayoutSettingsUserDataDocument = gql`
    query CommissionPayoutSettingsUserData {
  whoami {
    email
    id
  }
}
    `;

/**
 * __useCommissionPayoutSettingsUserDataQuery__
 *
 * To run a query within a React component, call `useCommissionPayoutSettingsUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommissionPayoutSettingsUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommissionPayoutSettingsUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommissionPayoutSettingsUserDataQuery(baseOptions?: Apollo.QueryHookOptions<CommissionPayoutSettingsUserDataQuery, CommissionPayoutSettingsUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommissionPayoutSettingsUserDataQuery, CommissionPayoutSettingsUserDataQueryVariables>(CommissionPayoutSettingsUserDataDocument, options);
      }
export function useCommissionPayoutSettingsUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommissionPayoutSettingsUserDataQuery, CommissionPayoutSettingsUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommissionPayoutSettingsUserDataQuery, CommissionPayoutSettingsUserDataQueryVariables>(CommissionPayoutSettingsUserDataDocument, options);
        }
export type CommissionPayoutSettingsUserDataQueryHookResult = ReturnType<typeof useCommissionPayoutSettingsUserDataQuery>;
export type CommissionPayoutSettingsUserDataLazyQueryHookResult = ReturnType<typeof useCommissionPayoutSettingsUserDataLazyQuery>;
export type CommissionPayoutSettingsUserDataQueryResult = Apollo.QueryResult<CommissionPayoutSettingsUserDataQuery, CommissionPayoutSettingsUserDataQueryVariables>;