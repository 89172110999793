import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { formatDistanceToNow } from 'date-fns'
import TableHeader, { TableHeaderCell } from './TableHeader'
import TableRow, { TableRowCell } from './TableRow'
import { FulfillmentAccountRowFragment } from './operations/fulfillment-account-row.generated'
import { INTEGRATIONS_NAME_MAP } from './constants'

const useStyles = makeStyles({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
})

export const OutboundTableHeader: React.FC = () => (
  <TableHeader>
    <TableHeaderCell>Platform</TableHeaderCell>
    <TableHeaderCell>API Key</TableHeaderCell>
    <TableHeaderCell>Last Push</TableHeaderCell>
    <TableHeaderCell />
    <TableHeaderCell />
    <TableHeaderCell />
  </TableHeader>
)

interface FulfillmentAccountRowProps {
  fulfillmentAccount: FulfillmentAccountRowFragment
  handleEdit: (fulfillmentAccount: FulfillmentAccountRowFragment) => void
  onTest(fulfillmentAccount: FulfillmentAccountRowFragment): void
  organizationName?: string
  disabled?: boolean
}

export const OutboundAccountRow: React.FC<FulfillmentAccountRowProps> = ({
  fulfillmentAccount,
  handleEdit,
  onTest,
  organizationName,
  disabled
}) => {
  const classes = useStyles()
  const { accountType, integrationName, key, lastPush } = fulfillmentAccount
  return (
    <TableRow>
      <TableRowCell>
        {integrationName || INTEGRATIONS_NAME_MAP[accountType]}
        {organizationName ? ` - ${organizationName}` : ''}
      </TableRowCell>
      <TableRowCell className={classes.truncated}>{key}</TableRowCell>
      <TableRowCell>{lastPush ? formatDistanceToNow(lastPush, { addSuffix: true }) : 'N/A'}</TableRowCell>
      <TableRowCell></TableRowCell>
      <TableRowCell style={{ textAlign: 'right' }}>
        {!disabled && (
            <Button size="small" variant="outlined" color="primary" onClick={(): void => handleEdit(fulfillmentAccount)}>
              Edit
            </Button>
        )}
      </TableRowCell>
      <TableRowCell style={{ textAlign: 'right' }}>
        <Button size="small" variant="outlined" color="primary" onClick={() => onTest(fulfillmentAccount)}>
          Test
        </Button>
      </TableRowCell>
    </TableRow>
  )
}
